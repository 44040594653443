import { baseURL } from '@/api/baseURL'
import axios from 'axios'
import { Message } from 'element-ui'
export default function upload (params, url) {
  Message.warning('正在导出中，请稍等....')
  axios({
    url: baseURL + url,
    method: 'POST',
    data: params,
    responseType: 'blob',
    withCredentials: true,
    processData: false,
    contentType: false,
    headers: {
      Authorization: localStorage.getItem('token') || ''
    }
  }).then((response) => {
    let name = ''
    // 线上  Content-Disposition
    // 本地  content-disposition    做判断
    if (response.headers['Content-Disposition']) {
      name = response.headers['Content-Disposition'].split(';')[1].split('=')[1]
    }
    if (response.headers['content-disposition']) {
      name = response.headers['content-disposition'].split(';')[1].split('=')[1]
    }
    const fileName = decodeURI(name)
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(
      new Blob([response.data], {
        type: 'application/vnd.ms-excel;charset=utf-8'
      })
    )
    link.target = '_blank'
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  })
}
