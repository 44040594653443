<template>
  <div class="layout_common">
    <div class="main_box">
      <div class="main_box_con">
        <div class="search_flex">
          <div class="search_left">
            <inputComp
              :hval="title"
              @blur="titleBlur"
              placeholder="任务名称"
            />
            <dateComp :hval="date" @change="dateChange" />
            <btnGroup @search="search" @reset="reset" />
          </div>
        </div>
        <tableComp
          :data="tableData"
          :col-configs="theadName"
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
         <el-table-column slot="status" label="状态">
            <template v-slot="scope">
              <span :style="{color:scope.row.status === 1?'red':''}">{{handleStatus(scope.row.status)}}</span>
            </template>
          </el-table-column>
        <el-table-column slot="operate" label="操作" fixed="right" width="130">
            <template v-slot="scope">
               <btnComp v-if="scope.row.status === 2" btnType="text" @click="download(scope.row)" v-permission="'下载'">下载</btnComp>
               <btnComp v-if="scope.row.status !== 1" style="color:red" btnType="text" @click="del(scope.row)" v-permission="'删除'">删除</btnComp>
            </template>
        </el-table-column>
        </tableComp>
      </div>
    </div>
     <dialogComp
        title="提示"
        :isShow="hintShow"
        @close="close"
        @sure="hintSure"
      >
      <p>确定删除吗？</p>
    </dialogComp>
  </div>
</template>
<script>
import { exportTaskList, exportTaskDel } from '@/api'
import upload from '@/utils/upload'
export default {
  components: {
  },
  data () {
    return {
      hintShow: false,
      curPage: 1,
      pageSize: 10,
      total: 0,
      title: '',
      startTime: '',
      endTime: '',
      date: [],
      tableData: [],
      theadName: [
        { prop: 'finishTime', label: '任务完成时间' },
        { prop: 'title', label: '任务名称' },
        { prop: 'name', label: '文档名称' },
        { slot: 'status' },
        { prop: 'createdTime', label: '任务开始时间' },
        { slot: 'operate' }
      ]
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      const params = {
        dataAuthority: true,
        curPage: this.curPage,
        pageSize: this.pageSize,
        title: this.title,
        startTime: this.startTime,
        endTime: this.endTime
      }
      exportTaskList(params).then((res) => {
        this.tableData = res.data.list
        this.total = res.data.totalCount
      })
    },
    // 处理状态
    handleStatus (status) {
      let str = ''
      switch (status) {
        case 1:
          str = '正在导出'
          break
        case 2:
          str = '已经完成'
          break
        case 3:
          str = '导出失败'
          break
      }
      return str
    },
    titleBlur (val) {
      this.title = val
    },
    dateChange (startTime, endTime) {
      this.startTime = startTime
      this.endTime = endTime
    },
    download (val) {
      const { name, path } = val
      upload(null, `/mgr/downloadFile/downloadFile?name=${name}&path=${path}`)
    },
    // 删除操作
    del (row) {
      this.id = row.id
      this.hintShow = true
    },
    hintSure () {
      exportTaskDel({ id: this.id }).then(_ => {
        this.$message.success('删除成功')
        this.close()
      })
    },
    close () {
      this.hintShow = false
      this.getList()
    },
    search () {
      this.curPage = 1
      this.getList()
    },
    reset () {
      this.curPage = 1
      this.title = ''
      this.startTime = ''
      this.endTime = ''
      this.date = []
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
<style lang='scss' scoped>
</style>
